import { type Theme } from 'planby';

export const theme: Theme = {
  gradient: { blue: { 300: '#0865B4', 600: '#1D3B54', 900: '#1D3B54' } },
  green: { 300: '#2c7a7b' },
  grey: { 300: '#d1d1d1' },
  loader: {
    bg: '#171923db',
    pink: '#F78EB6',
    purple: '#3437A2',
    teal: '#5DDADB',
  },
  // primary.600 is inactive program, primary.900 is background color of entire
  // schedule
  primary: { 600: '#1D3B54', 900: '#141416' },
  scrollbar: { border: '#ffffff', thumb: { bg: '#e1e1e1' } },
  text: { grey: { 300: '#a0aec0', 500: '#718096' } },
  timeline: { divider: { bg: '#718096' } },
  white: '#fff',
};
