'use client';

import {
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
  type FunctionComponent,
} from 'react';
import useStore from '@/store';
import { NitrousMediaSessionManager } from '@motortrend/nitrous-web-sdk';
import Hls from 'hls.js';

import { type Program } from '@/types/FastTv';

import { getCurrentlyPlayingProgram } from '@/utils/fastTv';
import getVideoWurlPayload from '@/utils/nitrous/payloads/getVideoWurlPayload';
import useStreamingVideoAds from '@/hooks/useStreamingVideoAds';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';

import {
  fastTvActiveChannelUuidSelector,
  fastTvActiveHlsStreamSelector,
} from '@/store/selectors';

import { injectParamsToStreamingUrl } from './injectParamsToStreamingUrl';

const Player: FunctionComponent<{ programs: Program[] }> = ({ programs }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const hlsRef = useRef<Hls | null>(null);

  const fastTvStreamUrl = useStore(fastTvActiveHlsStreamSelector);
  const activeChannelUuid = useStore(fastTvActiveChannelUuidSelector);
  const [ready, setReady] = useState(false);
  const videoElementId = useId();
  const mediaSessionManager = useRef(
    new NitrousMediaSessionManager(videoElementId),
  );

  const onVisibilityChange = useVisibilityChange();
  const { adsToken, pauseAds, playAds } = useStreamingVideoAds(containerRef);

  const updateUrl = useCallback(
    (adPath = '') => {
      let urlParams = injectParamsToStreamingUrl();
      if (adPath) {
        urlParams += `&ads.custom=${adPath}`;
      }
      return `${fastTvStreamUrl}${urlParams}`;
    },
    [fastTvStreamUrl],
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }

    const streamUrl = updateUrl(adsToken);

    if (Hls.isSupported()) {
      const hls = new Hls();
      hlsRef.current = hls;
      hls.loadSource(streamUrl);
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement
          .play()
          .catch((error) => console.error('Play failed:', error));
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // For browsers that natively support HLS (like Safari)
      videoElement.src = streamUrl;
      videoElement.addEventListener('loadedmetadata', () => {
        videoElement
          .play()
          .catch((error) => console.error('Play failed:', error));
      });
    }

    setReady(true);

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [adsToken, updateUrl]);

  useEffect(() => {
    onVisibilityChange((isVisible) => {
      if (isVisible) {
        playAds();
        videoRef.current
          ?.play()
          .catch((error) => console.error('Play failed:', error));
      } else {
        pauseAds();
        videoRef.current?.pause();
      }
    });
  }, [onVisibilityChange, playAds, pauseAds]);

  useEffect(() => {
    const activeProgram = getCurrentlyPlayingProgram(
      programs,
      new Date(),
      activeChannelUuid,
    );
    if (activeProgram && ready) {
      mediaSessionManager.current.init(getVideoWurlPayload(activeProgram));
    }
  }, [activeChannelUuid, ready, programs]);

  const handleClick = () => {
    playAds();
  };

  const handleEnded = () => {
    pauseAds();
  };

  const handlePlay = () => {
    playAds();
  };

  return (
    <div className="absolute inset-0" ref={containerRef}>
      <video
        className="h-full w-full"
        controls
        id={videoElementId}
        muted
        onClick={handleClick}
        onEnded={handleEnded}
        onPlay={handlePlay}
        playsInline
        ref={videoRef}
      />
    </div>
  );
};

export default Player;
