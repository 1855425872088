import { useCallback, useEffect, useRef } from 'react';

type VisibilityChangeCallback = (isVisible: boolean) => void;

export function useVisibilityChange(): (
  callback: VisibilityChangeCallback,
) => void {
  const callbackRef = useRef<VisibilityChangeCallback | null>(null);

  const handleVisibilityChange = useCallback(() => {
    if (!callbackRef.current) {
      return;
    }

    callbackRef.current(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    handleVisibilityChange();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const onVisibilityChange = useCallback(
    (callback: VisibilityChangeCallback) => {
      callbackRef.current = callback;
    },
    [],
  );

  return onVisibilityChange;
}
