import { useState, type FunctionComponent } from 'react';
import useStore from '@/store';
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramImage,
  ProgramStack,
  ProgramText,
  ProgramTitle,
  useProgram,
  type ProgramItem,
} from 'planby';

import { getHlsUrl } from '@/utils/fastTv';
import ProgramDetailModal from '@/components/FastTv/ProgramDetailModal';

import { fastTvActiveHlsStreamSelector } from '@/store/selectors';

const Program: FunctionComponent<ProgramItem> = ({ program, ...rest }) => {
  const { setActiveChannelUuid, setActiveHlsStream } = useStore();
  const fastTvStreamUrl = useStore(fastTvActiveHlsStreamSelector);
  const { formatTime, isLive, isMinWidth, set12HoursTimeFormat, styles } =
    useProgram({
      program,
      ...rest,
    });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: { image, rating, since, till, title },
  } = program;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  const handleOnContentClick = () => {
    // If isLive and user selects the currently playing program; display the modal
    // If isLive and user selects a program on a different channel; load that channel
    // Else program is not live; display the modal
    if (isLive) {
      const hlsUrl = getHlsUrl(program.data.channelUuid);

      if (fastTvStreamUrl !== hlsUrl) {
        setActiveHlsStream(hlsUrl);
        setActiveChannelUuid(program.data.channelUuid);
      } else {
        setIsModalOpen(true);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setTimeout(() => {
      setIsModalOpen(false);
    }, 1);
  };

  return (
    <>
      <ProgramDetailModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        program={program}
      />
      <ProgramBox
        onClick={handleOnContentClick}
        style={{ ...styles.position, padding: '4px 0 4px 8px' }}
        width={styles.width}
      >
        <ProgramContent isLive={isLive} width={styles.width}>
          <ProgramFlex>
            {isLive && isMinWidth && <ProgramImage alt={title} src={image} />}
            <ProgramStack>
              <ProgramTitle>{title}</ProgramTitle>
              <ProgramText>
                {rating} | {sinceTime} - {tillTime}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
    </>
  );
};

export default Program;
