import { env } from '@/config';
import {
  VideoSource,
  VideoType,
  type VideoPayload,
} from '@motortrend/nitrous-web-sdk';

import { type Program } from '@/types/FastTv';

import getVideoTitle from '../getVideoTitle';

const getVideoWurlPayload = (activeProgram: Program): VideoPayload => {
  const episodeNumber = activeProgram.episodeNumber || 0;
  const seasonNumber = activeProgram.seasonNumber || 0;

  const channel = env.fastTv.channels.find(
    (channel) => channel.uuid === activeProgram.channelUuid,
  );

  return {
    videoCasted: false,
    videoCategory: 'FAST TV',
    videoChannelName: channel?.name,
    videoDurationSeconds: activeProgram.Runtime * 60,
    videoEpisodeNumber: activeProgram.episodeNumber,
    videoId: activeProgram.id,
    videoPublicationDate: new Date(activeProgram.Released).toJSON(),
    videoSeasonName: `${activeProgram.seasonNumber}`,
    videoShowId: `${activeProgram.showId}`,
    videoShowName: activeProgram.showTitle,
    videoSource: VideoSource.wurl,
    videoTitle: getVideoTitle(
      activeProgram.showTitle,
      seasonNumber,
      episodeNumber,
      activeProgram.shortDesc,
    ),
    videoType: VideoType.long_form,
  };
};

export default getVideoWurlPayload;
