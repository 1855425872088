import { Fragment, type FunctionComponent } from 'react';
import { FastTvStrings as strings } from '@/translations';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  Link,
  Typography,
} from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';
import { normalizeUrl } from '@/utils/normalizeUrl';
import SectionHeader from '@/components/FastTv/SectionHeader';

import {
  type Answer,
  type FAQItem,
  type ScheduleItem,
  type SocialLinks,
} from './FastTvFAQ.props';

const FastTvFAQ: FunctionComponent = () => {
  const isScheduleItem = (answer: Answer): answer is ScheduleItem => {
    return (answer as ScheduleItem).schedule !== undefined;
  };

  const isSocialLinks = (answer: Answer): answer is SocialLinks => {
    return (answer as SocialLinks).links !== undefined;
  };

  const renderAnswer = (answer: Answer) => {
    if (typeof answer === 'string') {
      return (
        <Typography className="text-neutral-6" variant="body2">
          {answer}
        </Typography>
      );
    } else if (isScheduleItem(answer)) {
      return (
        <>
          <Typography className="text-neutral-6" variant="body2">
            {answer.scheduleHeading}
          </Typography>
          <ul className="list-disc pl-7">
            {answer.schedule.map((item, index) => (
              <li key={index}>
                <Typography className="text-neutral-6" variant="body2">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </>
      );
    } else if (isSocialLinks(answer)) {
      return (
        <>
          <Typography className="text-neutral-6" variant="body2">
            {answer.linksHeading}
          </Typography>
          <ul className="list-disc pl-7">
            {Object.entries(answer.links).map(([key, { text, url }]) => (
              <li key={key}>
                <Typography className="text-neutral-6" variant="body2">
                  <Link
                    as="a"
                    data-id={DataId.FastTvFAQLink}
                    data-parent={DataId.FastTv}
                    href={normalizeUrl({ paths: [url] })}
                    target="_blank"
                  >
                    {text}
                  </Link>
                </Typography>
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  return (
    <div>
      <SectionHeader text={strings.Typography.FAQBlock.Heading} />
      <Accordion className="w-full text-lg text-neutral-8" type="multiple">
        {Object.entries(strings.Typography.FAQBlock.Questions).map(
          ([key, { answer, question }]: [string, FAQItem], index) => {
            if (!question || !answer) {
              return null;
            }

            return (
              <Fragment key={key}>
                <AccordionItem
                  className="border-t border-neutral-3"
                  value={`${index + 1}`}
                >
                  <AccordionHeader className="py-6 text-left text-neutral-8">
                    {question}
                  </AccordionHeader>
                  <AccordionContent>{renderAnswer(answer)}</AccordionContent>
                </AccordionItem>
              </Fragment>
            );
          },
        )}
      </Accordion>
    </div>
  );
};

export default FastTvFAQ;
