import { env } from '@/config';
import { CacheTags, CacheTTL } from '@/constants/cache';

import { type FastTvSchedule, type ScheduleEntity } from '@/types/FastTv';

export async function getFastTvSchedule(): Promise<FastTvSchedule[]> {
  try {
    const promises = env.fastTv.channels.map((channel) =>
      fetch(channel.epgUrl, {
        // While we specify a 6hr cache ttl here, cloudfront (which hosts the
        // epg data) also has its own layer of cache. If necessary we can safely
        // disable nextjs' data cache for this request and rely on cloudfront's
        // cache
        next: {
          revalidate: Number(CacheTTL.fastTvSchedule),
          tags: [CacheTags.fastTvSchedule],
        },
      }),
    );
    const responses = await Promise.all(promises);

    let data = await Promise.all(responses.map((res) => res.json()));

    // To support multichannel Fast TV, inject channelUuid into `properties` for
    // each object in the entities array.
    let i = 0;

    data = data.map((schedule) => {
      schedule.entities = schedule.entities.map((entity: ScheduleEntity) => {
        entity.properties.channelUuid = env.fastTv.channels[i].uuid;

        return entity;
      });

      i++;

      return schedule;
    });

    return data;
  } catch (error) {
    throw new Error(`Failed to fetch Fast TV Schedule: ${error}`);
  }
}
