import { type Program } from 'planby/dist/Epg/helpers/interfaces';

export const localizeTimeString = (
  utcDateStr: Program['since'] | Program['till'],
): string => {
  // Get the local time in the HH:mm A format
  const localTime = new Date(utcDateStr).toLocaleTimeString('en-US', {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });

  return localTime;
};
