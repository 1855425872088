import { type Program } from '@/types/FastTv';

// Returns the `program`that is between `since` and `till` based on the current date.
// @todo write test
export const getCurrentlyPlayingProgram = (
  programs: Program[],
  currentDate: Date,
  channelUuid: string,
): Program | null => {
  const currentTime = currentDate.getTime();

  for (const program of programs) {
    const sinceTime = new Date(program.since).getTime();
    const tillTime = new Date(program.till).getTime();

    if (
      currentTime >= sinceTime &&
      currentTime < tillTime &&
      program.channelUuid === channelUuid
    ) {
      return program;
    }
  }

  return null;
};
