import { type FunctionComponent } from 'react';
import Image from 'next/image';
import { FastTvStrings as strings } from '@/translations';
import { Typography } from '@motortrend/ids';

import ChannelFastTvLogo from '@/assets/images/fasttv/channel_fast_tv.png';

const FastTvHeader: FunctionComponent = () => {
  return (
    <div className="mb-6 flex w-full items-center justify-between">
      <Image
        alt={strings.LogoAltText}
        height={32}
        src={ChannelFastTvLogo}
        width={110}
      />
      <Typography align="right" variant="subtitle2">
        {strings.Typography.Tagline.One}{' '}
        <span className="text-primary-2">{strings.Typography.Tagline.Two}</span>
      </Typography>
    </div>
  );
};

export default FastTvHeader;
