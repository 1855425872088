import hasWindow from '@/utils/hasWindow';

type GeoLocationData = {
  continent: string;
  country: string;
  state: string;
  stateName: string;
};

export const getGeolocationData = (): GeoLocationData => {
  if (hasWindow && '__otccpaooLocation' in window) {
    return window.__otccpaooLocation;
  }

  return {
    continent: '',
    country: 'NA',
    state: '',
    stateName: '',
  };
};
