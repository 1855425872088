import { type AdConfig } from '@/types/AdConfig';
import { AdTargetingTemplate } from '@/types/AdTargeting';

import isProd from '@/utils/isProd';

export const fastTVAdConfigBuilder = (): AdConfig => {
  return {
    adPath: '/3833/motortrend.primedia.com/fasttv/fasttv_web',
    adTargeting: {
      brand: 'motortrend',
      car: 'new',
      chan: isProd ? 'autos' : 'test',
      custom_device: 'web',
      dyn: '',
      k: 'index',
      sect: 'video',
      site: 'motortrend',
      subs: 'fasttv',
      template: AdTargetingTemplate.video_page,
      videoplayer: 'true',
    },
    defaultVastTemplate: '',
  };
};
