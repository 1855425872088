import { Suspense, type FunctionComponent } from 'react';
import { LayoutGrid, LayoutGridContent, LayoutGridRail } from '@motortrend/ids';
import { TrackPage } from '@motortrend/nitrous-web-sdk';

import ConfigurableSlot from '@/utils/ads/googlePublisherTag/ConfigurableAdSlot';
import LeaderboardAdSlot from '@/utils/ads/googlePublisherTag/LeaderboardAd';
import { fastTVAdConfigBuilder } from '@/utils/ads/video/fastTVAdConfigBuilder';
import { transformEpgData } from '@/utils/fastTv';
import { fastTvAdPath } from '@/utils/fastTv/fastTvConfig';
import getFastTvPageAnalyticsPayload from '@/utils/nitrous/payloads/getFastTvPageAnalyticsPayload';
import FastTvAbout from '@/components/FastTv/FastTvAbout';
import FastTvFAQ from '@/components/FastTv/FastTvFAQ';
import FastTvHeader from '@/components/FastTv/FastTvHeader/FastTvHeader';
import Providers from '@/components/FastTv/Providers';
import Schedule from '@/components/FastTv/Schedule';
import StreamingPlayer from '@/components/FastTv/StreamingPlayer';

import AdConfigSetup from '../AdConfigSetup';
import StickyAd from '../StickyAd';
import { type FastTvProps } from './FastTv.props';

const FastTv: FunctionComponent<FastTvProps> = ({ schedule }) => {
  const programs = transformEpgData(schedule.entities);
  const adConfig = fastTVAdConfigBuilder();
  const payload = getFastTvPageAnalyticsPayload(adConfig);

  return (
    <>
      <TrackPage payload={payload} />
      <Suspense>
        <AdConfigSetup adConfig={adConfig} />
      </Suspense>
      <StickyAd className="z-40">
        <LeaderboardAdSlot adPath={fastTvAdPath} pageTargeting={adConfig} />
      </StickyAd>
      <LayoutGrid>
        <LayoutGridContent data-nitrous-content-readable>
          <div className="pb-8 lg:pb-12">
            <FastTvHeader />
            <StreamingPlayer programs={programs} />
          </div>
          <Schedule programs={programs} />
        </LayoutGridContent>
        <LayoutGridRail>
          <div className="hidden space-y-4 lg:block">
            <div className="min-h-[450px]">
              <ConfigurableSlot
                className="sticky top-0"
                pageTargeting={adConfig}
                size={[[300, 250]]}
                sizeMapping={[
                  [
                    [1024, 0],
                    [300, 250],
                  ],
                  [[0, 0], []],
                ]}
                targeting={{ ...adConfig.adTargeting, pos: ['top', '1'] }}
              />
            </div>
            <div className="min-h-[700px]">
              <ConfigurableSlot
                className="sticky top-0"
                pageTargeting={adConfig}
                size={[
                  [300, 250],
                  [300, 600],
                ]}
                sizeMapping={[
                  [
                    [1024, 0],
                    [
                      [300, 250],
                      [300, 600],
                    ],
                  ],
                  [[0, 0], []],
                ]}
                targeting={{ ...adConfig.adTargeting, pos: ['middle', '2'] }}
              />
            </div>
          </div>
        </LayoutGridRail>
      </LayoutGrid>
      <Providers />
      <FastTvAbout />
      <FastTvFAQ />
    </>
  );
};

export default FastTv;
