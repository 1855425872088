'use client';

import {
  useCallback,
  useEffect,
  useState,
  type FunctionComponent,
} from 'react';
import Image from 'next/image';
import { FastTvConstants } from '@/constants';
import { FastTvStrings as strings } from '@/translations';
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  LayoutGrid,
  LayoutGridContent,
  type CarouselApi,
} from '@motortrend/ids';
import { type EmblaCarouselType, type EmblaEventType } from 'embla-carousel';
import { withErrorBoundary } from 'react-error-boundary';

import { DataId } from '@/utils/nitrous/constants';
import { NoUiErrorFallback } from '@/components/ErrorFallback';
import SectionHeader from '@/components/FastTv/SectionHeader';
import { NextLink } from '@/components/NextLink';

const Providers: FunctionComponent = () => {
  const [api, setApi] = useState<CarouselApi>();

  const onSelectListener = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (api: EmblaCarouselType, event: EmblaEventType) => {
      // do stuff
    },
    [],
  );

  const removeOnSelectListener = useCallback(() => {
    if (!api) {
      return;
    }

    api.off('select', onSelectListener);
  }, [api, onSelectListener]);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on('select', onSelectListener);

    return () => {
      removeOnSelectListener();
    };
  }, [api, onSelectListener, removeOnSelectListener]);

  return (
    <LayoutGrid as="section" data-package="FastTV Providers">
      <LayoutGridContent fullWidth>
        <SectionHeader text={strings.Typography.ProviderHeading} />
        <Carousel setApi={setApi}>
          <CarouselContent>
            {FastTvConstants.ProviderTiles.map(({ href, image, name }) => (
              <CarouselItem key={`CarouselItem-${name}`}>
                <Card
                  as={NextLink}
                  data-id={DataId.FastTvProviderLink}
                  data-parent={DataId.FastTv}
                  href={href}
                  target="_blank"
                >
                  <div className="min-w-20" data-ids="CardMedia">
                    <div className="relative aspect-[1.18/1] w-full overflow-hidden">
                      <Image alt={name} fill src={image} />
                    </div>
                  </div>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </LayoutGridContent>
    </LayoutGrid>
  );
};

export default withErrorBoundary(Providers, {
  FallbackComponent: NoUiErrorFallback,
});
