export enum AdTargetingTemplate {
  article = 'article',
  article_index = 'article_index',
  article_page_aftermarket = 'article_page_aftermarket',
  article_page_inmarket = 'article_page_inmarket',
  brand_index = 'brand_index',
  buyersguide = 'buyersguide',
  certified = 'certified',
  classifieds = 'classifieds',
  clickthrough = 'clickthrough',
  ctt = 'ctt',
  listicle = 'listicle',
  magazine = 'magazine',
  miscellaneous_pages = 'miscellaneous_pages',
  mt_homepage = 'mt_homepage',
  video_page = 'video_page',
}

export type AdTargeting = {
  bodystyle?: string;
  bodystyleonly?: string;
  brand?: string;
  car?: string;
  category?: string;
  chan?: string;
  class?: string;
  classonly?: string;
  column?: string;
  custom_device?: string;
  duration?: string;
  dyn?: string;
  fluid?: string;
  freeform_tags?: string;
  height?: string;
  id?: string;
  k?: string;
  make?: string;
  makeonly?: string;
  model?: string;
  // TODO: after lithium is deprecated, we should remove this value and replace
  // with something like app_name: <package.json>.name
  nextweb?: 'true';
  native?: string;
  ntvPlacement?: string;
  page?: string;
  ppc?: string;
  sect?: string;
  site?: string;
  sm_id?: string;
  subs?: string;
  subss?: string;
  topic?: string;
  test?: string;
  pos?: string | string[];
  template?: AdTargetingTemplate;
  trim?: string;
  videoplayer?: string;
  ObjectId?: string;
  year?: string;
  content_type?: string;
  sponsored?: string;
  width?: string;
};
