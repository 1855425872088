import { type FunctionComponent } from 'react';
import { FastTvStrings as strings } from '@/translations';
import { Typography } from '@motortrend/ids';

import SectionHeader from '@/components/FastTv/SectionHeader';

const FastTvAbout: FunctionComponent = () => {
  return (
    <div>
      <SectionHeader text={strings.Typography.InfoBlock.Heading} />
      <Typography className="gutterBottom text-neutral-6" variant="body2">
        {strings.Typography.InfoBlock.Body}
      </Typography>
    </div>
  );
};

export default FastTvAbout;
