import { nitrous } from '@motortrend/nitrous-web-sdk';
import { v4 as uuidv4 } from 'uuid';

/**
 * Generates a nonce for video streaming requests.
 * https://developers.google.com/ad-manager/pal/html5
 * @param {Object} playerRef - A ref object pointing to the player.
 * @returns {Promise} Returns a promise that resolves to the nonce manager or rejects with an error.
 */
async function generateNonce(videoContainer: HTMLDivElement) {
  const width = videoContainer.offsetWidth;
  const height = videoContainer.offsetHeight;

  const consentSettings = new window.goog.pal.ConsentSettings();
  consentSettings.allowStorage = true; // Assume consent is given, replace with actual consent logic
  const mtid = nitrous.user.getMtid();

  const request = new window.goog.pal.NonceRequest({
    adWillAutoPlay: true,
    adWillPlayMuted: false,
    continuousPlayback: true,
    descriptionUrl: 'https://motortrend.com/fasttv',
    iconsSupported: true,
    playerType: 'Fast TV',
    playerVersion: '1.0',
    ...(mtid && { ppid: mtid }),
    sessionId: uuidv4(),
    // Player support for VPAID 2.0, OMID 1.0, and SIMID 1.1
    supportedApiFrameworks: '2,7,9',
    videoHeight: height,
    videoWidth: width,
  });

  const nonceLoader = new window.goog.pal.NonceLoader(consentSettings);
  try {
    const manager = await nonceLoader.loadNonceManager(request);
    return manager; // Returns the nonce manager for further processing
  } catch (error) {
    console.error('Error generating nonce:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export default generateNonce;
