'use client';

import { useRef, type FunctionComponent } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { NoUiErrorFallback } from '../../ErrorFallback';
import Player from './Player';
import { type StreamingPlayerProps } from './StreamingPlayer.props';

const StreamingPlayer: FunctionComponent<StreamingPlayerProps> = ({
  programs,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative w-full overflow-hidden rounded-lg bg-neutral-3 pt-[56.25%]"
      ref={containerRef}
    >
      <Player programs={programs} />
    </div>
  );
};

export default withErrorBoundary(StreamingPlayer, {
  FallbackComponent: NoUiErrorFallback,
});
