import { type Program, type ScheduleEntity } from '@/types/FastTv';

import { convertTo24hr, isDSTInEST } from '@/utils/fastTv';

export const transformEpgData = (entities: ScheduleEntity[]): Program[] => {
  const programDetails = entities.map((item) => {
    const {
      airDate,
      channelUuid,
      description = '',
      duration: Runtime = 0,
      episodeNumber,
      id,
      images,
      originalAirDate = '',
      seasonNumber,
      showId,
      showTitle = '',
      title = '',
    } = item.properties;

    const timeSlot = convertTo24hr(item.properties.timeSlot, isDSTInEST());
    const timeZone = 'EST' || item.properties.timeZone;

    // Swap - for / to fix rangeError: Invalid Date in Safari
    const startTime = new Date(
      `${airDate.replace(/-/g, '/')} ${timeSlot} ${timeZone}`,
    );

    return {
      channelUuid,
      description,
      episodeNumber,
      id,
      image: images[0]?.uri ?? '',
      rating: 'TV-PG',
      Released: originalAirDate,
      Runtime,
      seasonNumber,
      shortDesc: title,
      showId,
      showTitle,
      since: startTime.toISOString(),
      till: new Date(
        startTime.setMinutes(startTime.getMinutes() + Runtime),
      ).toISOString(),
      title: showTitle ? `${showTitle} : ${title}` : title,
    };
  });

  return programDetails.sort((a, b) => {
    if (a.since > b.since) {
      return 1;
    }

    if (a.since < b.since) {
      return -1;
    }

    return 0;
  });
};
