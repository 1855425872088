import { type FunctionComponent } from 'react';
import {
  TimelineBox,
  TimelineDivider,
  TimelineDividers,
  TimelineTime,
  TimelineWrapper,
  useTimeline,
} from 'planby';

import { type TimelineProps } from './Timeline.props';

const Timeline: FunctionComponent<TimelineProps> = ({
  dayWidth,
  hourWidth,
  isBaseTimeFormat,
  isSidebar,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth,
}) => {
  const { dividers, formatTime, time } = useTimeline(
    numberOfHoursInDay,
    isBaseTimeFormat,
  );

  const renderTime = (index: number) => (
    <TimelineBox key={index} width={hourWidth}>
      <TimelineTime>
        {formatTime(index + offsetStartHoursRange).toUpperCase()}
      </TimelineTime>
      <TimelineDividers>{renderDividers()}</TimelineDividers>
    </TimelineBox>
  );

  const renderDividers = () =>
    dividers.map((_, index) => (
      <TimelineDivider key={index} width={hourWidth} />
    ));

  return (
    <TimelineWrapper
      dayWidth={dayWidth}
      isSidebar={isSidebar}
      sidebarWidth={sidebarWidth}
      style={{ height: '64px' }}
    >
      {time.map((_, index) => renderTime(index))}
    </TimelineWrapper>
  );
};

export default Timeline;
