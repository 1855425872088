import { AdTargetingTemplate } from '@/types/AdTargeting';

import isProd from '../isProd';

export const pageLevelTarget = {
  brand: 'motortrend',
  car: 'new',
  chan: isProd ? 'autos' : 'test',
  custom_device: 'web',
  dyn: '',
  k: 'index',
  sect: 'video',
  site: 'motortrend',
  subs: 'fasttv',
  template: AdTargetingTemplate.video_page,
  videoplayer: 'true',
};

// note: AdOps decision to use BG ad Path on FastTv Page
export const fastTvAdPath = '/3833/motortrend.primedia.com/buyersguide';
