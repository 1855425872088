import { ContentType, type PageViewPayload } from '@motortrend/nitrous-web-sdk';

import { type AdConfig } from '@/types/AdConfig';

import { Brand } from '@/lib/gql/graphql';
import { ViewGroup, ViewName } from '@/utils/nitrous/constants';

// @todo write test
const getFastTvPageAnalyticsPayload = (
  adConfig: AdConfig,
): PageViewPayload | undefined => {
  try {
    return {
      content_brand: Brand.Motortrend,
      content_type: ContentType.FAST_TV,
      view_group: ViewGroup.Watch,
      view_name: ViewName.FastTv,
      view_template: adConfig.adTargeting.template || 'null',
    };
  } catch (e) {
    console.error(e);
  }
};

export default getFastTvPageAnalyticsPayload;
