import { type FunctionComponent } from 'react';
import { Typography } from '@motortrend/ids';

import { type SectionHeaderProps } from './SectionHeader.props';

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({ text }) => {
  return (
    <Typography gutterBottom lg="h4" variant="h5">
      {text}
    </Typography>
  );
};

export default SectionHeader;
