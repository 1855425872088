import { type FunctionComponent } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from '@motortrend/ids';

import { localizeTimeString } from '@/utils/fastTv';

import { type ProgramDetailModalProps } from './ProgramDetailModal.props';

const ProgramDetailModal: FunctionComponent<ProgramDetailModalProps> = ({
  isOpen,
  onClose,
  program,
}) => {
  const {
    data: { description, rating, shortDesc, showTitle, since, till },
  } = program;

  return (
    <Dialog onClose={onClose} open={isOpen} theme="dark">
      <DialogTitle hideBorder variant="h4">
        {showTitle}
        <Typography variant="subtitle1">{shortDesc}</Typography>
      </DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogFooter className="justify-between">
        <Typography variant="caption1">{rating}</Typography>
        <Typography variant="caption1">
          {localizeTimeString(since)} - {localizeTimeString(till)}
        </Typography>
      </DialogFooter>
    </Dialog>
  );
};

export default ProgramDetailModal;
