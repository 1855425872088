'use client';

import { type FC } from 'react';

import { type AdConfig } from '@/types/AdConfig';

import useAdSlot from '../useAdSlot';

const LeaderboardAdSlot: FC<{
  adPath?: string;
  pageTargeting: AdConfig;
}> = ({ adPath, pageTargeting }) => {
  useAdSlot({
    id: 'gpt-ad-top-leaderboard',
    path: adPath || pageTargeting.adPath,
    size: [
      [970, 90],
      [728, 90],
      [320, 50],
    ],
    sizeMapping: [
      [
        [1024, 0],
        [
          [970, 90],
          [728, 90],
        ],
      ], // Desktop
      [
        [768, 0],
        [
          [320, 50],
          [728, 90],
        ],
      ], // Tablet
      [
        [0, 0],
        [320, 50],
      ], // Mobile
    ],
    targeting: { ...pageTargeting.adTargeting, pos: ['top', '1'] },
  });

  return (
    <div
      className="mb-4 flex min-h-[50px] justify-center lg:min-h-[90px]"
      id="gpt-ad-top-leaderboard"
    />
  );
};

export default LeaderboardAdSlot;
