import { env } from '@/config';
import { type Channel } from 'planby/dist/Epg/helpers/interfaces';

import { getGeolocationData } from '@/utils/getGeolocationData';

export const getChannels = (): Channel[] => {
  const geolocationData = getGeolocationData();

  return env.fastTv.channels.map((channel) => {
    return {
      country: geolocationData.country,
      logo: channel.logo,
      title: channel.name,
      type: 'channel',
      uuid: channel.uuid,
    };
  });
};
