const getVideoTitle = (
  showName: string | null | undefined,
  seasonNumber: string | number | null | undefined,
  episodeNumber: string | number | null | undefined,
  episodeName: string | null | undefined,
) => {
  const prefix = ' : ';
  let videoTitle = '';
  if (showName) {
    videoTitle += showName;
  }
  if (seasonNumber) {
    videoTitle += `${videoTitle ? prefix : ''}S${seasonNumber}`;
  }
  if (episodeNumber) {
    videoTitle += `${videoTitle ? prefix : ''}E${episodeNumber}`;
  }
  if (episodeName) {
    videoTitle += `${videoTitle ? prefix : ''}${episodeName}`;
  }

  return videoTitle;
};

export default getVideoTitle;
