import {
  DayInMilliseconds,
  FastTvConstants,
  MinuteInMilliseconds,
  SecondInMilliseconds,
} from '@/constants';
import { type Channel, type Program } from 'planby/dist/Epg/helpers/interfaces';

import { theme } from '@/components/FastTv';

export const buildUseEpgArgs = (channels: Channel[], programs: Program[]) => {
  const currentTime = new Date();

  const startDate = new Date(
    currentTime.getTime() -
      currentTime.getTimezoneOffset() * MinuteInMilliseconds,
  );

  // planby takes time only in full hours
  startDate.setMinutes(0, 0, 0);

  const startTime = Math.floor(
    new Date(startDate).getTime() / SecondInMilliseconds,
  );

  // planby takes end time at start day end
  currentTime.setHours(0, 0, 0);

  const endDate = new Date(
    currentTime.getTime() +
      DayInMilliseconds -
      currentTime.getTimezoneOffset() * MinuteInMilliseconds,
  );

  const endTime = Math.floor(endDate.getTime() / SecondInMilliseconds);
  // Arbitrarily set the width of a day to shorten/lengthen timeline
  const dayWidth = Math.floor(((endTime - startTime) * 10) / 144) + 500;

  return {
    channels,
    dayWidth,
    epg: programs,
    isBaseTimeFormat: true,
    isLine: true,
    isSidebar: true,
    itemHeight: FastTvConstants.EpgBaseHeight,
    sidebarWidth: FastTvConstants.EpgBaseWidth,
    startDate: startDate.toISOString().slice(0, 19),
    theme,
  };
};
