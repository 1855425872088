import { env } from '@/config';

export const getHlsUrl = (channelUuid: string): string => {
  const channel = env.fastTv.channels.find(
    (channel) => channel.uuid === channelUuid,
  );

  if (channel) {
    return channel.hlsUrl;
  }

  // default return is the first channel url
  return env.fastTv.channels[0].hlsUrl;
};
