import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type MutableRefObject,
} from 'react';

import type { NonceManager } from '@/types/Pal';

import generateNonce from '@/utils/fastTv/generateNonce';
import loadScript from '@/utils/onetrust/loadScript';

const useStreamingVideoAds = (
  videoContainerRef: MutableRefObject<HTMLDivElement | null>,
) => {
  const [adsManager, setAdsManager] = useState<NonceManager | null>(null);
  const [adsLibraryLoaded, setAdsLibraryLoaded] = useState(false);

  const onAdError = useCallback((error = '') => {
    console.error(
      'Error loading the PAL SDK script or generating nonce:',
      error,
    );
  }, []);

  useEffect(() => {
    if (!adsLibraryLoaded) {
      return;
    }

    async function initializeNonce() {
      const videoContainer = videoContainerRef.current;
      if (!videoContainer) {
        return;
      }

      const manager = await generateNonce(videoContainer);

      if (manager) {
        setAdsManager(manager as NonceManager);
      } else {
        onAdError();
      }
    }

    initializeNonce();
  }, [adsLibraryLoaded, onAdError, videoContainerRef]);

  useEffect(() => {
    const scriptUrl = 'https://imasdk.googleapis.com/pal/sdkloader/pal.js';
    const loadPal = loadScript(scriptUrl);

    loadPal
      ?.then(() => {
        setAdsLibraryLoaded(true);
      })
      .catch(() => {
        onAdError();
      });

    return () => {
      const loadedScript = document.querySelector(`script[src="${scriptUrl}"]`);
      if (loadedScript) {
        loadedScript.remove();
      }
    };
  }, [onAdError]);

  const playAds = useCallback(() => {
    adsManager?.sendPlaybackStart();
  }, [adsManager]);

  const pauseAds = useCallback(() => {
    adsManager?.sendPlaybackEnd();
  }, [adsManager]);

  const adsToken = useMemo(() => adsManager?.getNonce() ?? '', [adsManager]);

  return { adsToken, pauseAds, playAds };
};

export default useStreamingVideoAds;
