import { type FunctionComponent } from 'react';
import { FastTvConstants } from '@/constants';
import { ChannelBox, ChannelLogo } from 'planby';

import { type ChannelItemProps } from './ChannelItem.props';

const ChannelItem: FunctionComponent<ChannelItemProps> = ({ channel }) => {
  return (
    <ChannelBox style={{}} {...channel.position}>
      {/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
      {/* Or stay with default styles */}
      <ChannelLogo
        alt={channel.title}
        src={channel.logo}
        style={{
          maxHeight: FastTvConstants.EpgBaseHeight,
          maxWidth: FastTvConstants.EpgBaseWidth,
          padding: '10px',
        }}
      />
    </ChannelBox>
  );
};

export default ChannelItem;
