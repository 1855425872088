import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

/**
 * Adds tracking and configuration parameters to a streaming URL.
 * Integrates advertising and privacy parameters for user tracking
 * and ad customization based on user preferences and regulatory compliance.
 *
 * @returns {string} A string containing URL parameters.
 */
export const injectParamsToStreamingUrl = () => {
  // Base parameters for advertisement configurations
  let urlParams =
    '?ads.wurl_channel=1270&ads.wurl_name=MotorTrendFastTV&ads.coppa=0&ads.app_name=MT-WEB';

  // Retrieve or generate a unique device ID, store it in cookies for future use
  const deviceId = Cookies.get('deviceId') || uuidv4().replace('-', '');
  urlParams += `&ads.device_id=${deviceId}`;
  // Always assume the device type is web for this configuration
  urlParams += `&ads.custom_device=web`;

  Cookies.set('deviceId', deviceId, { expires: 365 });

  if (process.browser) {
    // Check for 'Do Not Track' setting in the browser
    const dntEnabled =
      navigator.doNotTrack === '1' || window.doNotTrack === '1';
    urlParams += `&ads.dnt=${dntEnabled}`;

    // Add US privacy string from cookie or window variable
    const usPrivacy = window.__US_PRIVACY__ || Cookies.get('usprivacy');
    urlParams += `&ads.us_privacy=${usPrivacy}`;

    // GDPR and consent checks if OneTrust cookie management exists
    if (window.OneTrust) {
      const gdprEnabled = Cookies.get('OptanonConsent');
      urlParams += `&ads.gdpr=${gdprEnabled}`;

      const consentData = Cookies.get('CONSENT') || '0';
      urlParams += `&ads.consent=${consentData}`;
    }

    // Device ID format - assuming it as DPID for digital platform identifier
    urlParams += `&ads.ifa_type=dpid`;
  }

  return urlParams;
};
