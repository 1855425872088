'use client';

import { type FunctionComponent } from 'react';
import cx from 'clsx';
import { Epg, Layout, useEpg } from 'planby';
import { withErrorBoundary } from 'react-error-boundary';

import { buildUseEpgArgs, getChannels } from '@/utils/fastTv';
import { NoUiErrorFallback } from '@/components/ErrorFallback';
import ChannelItem from '@/components/FastTv/ChannelItem';
import Program from '@/components/FastTv/Program';
import Timeline from '@/components/FastTv/Timeline';

import { type ScheduleProps } from './Schedule.props';

const Schedule: FunctionComponent<ScheduleProps> = ({ programs }) => {
  const channels = getChannels();

  const { getEpgProps, getLayoutProps } = useEpg({
    ...buildUseEpgArgs(channels, programs),
  });

  const epgProps = getEpgProps();

  return (
    // @note this adjusts the height of the schedule based on the number of
    // channels we have in increments of 5rem
    <div
      className={cx('w-full rounded-lg bg-neutral-1 px-0.5', {
        'h-44': channels.length === 1,
        'h-64': channels.length === 2,
        'h-[21rem]': channels.length > 2,
      })}
    >
      <Epg {...epgProps}>
        {
          /* When <Timeline /> renders on the server, the time is rendered based
           * on the server time. When we hydrate an error is thrown due to the
           * mismatch between server and browser times. To fix this, we'll wait
           * to render <Layout /> until we have access to the DOM by checking
           * the `ref` prop returned by getEpgProps()
           */
          epgProps.ref.current && (
            <Layout
              {...getLayoutProps()}
              renderChannel={({ channel }) => (
                <ChannelItem channel={channel} key={channel.uuid} />
              )}
              renderProgram={({ program, ...rest }) => (
                <Program key={program.data.id} program={program} {...rest} />
              )}
              renderTimeline={(properties) => <Timeline {...properties} />}
            />
          )
        }
      </Epg>
    </div>
  );
};

export default withErrorBoundary(Schedule, {
  FallbackComponent: NoUiErrorFallback,
});
